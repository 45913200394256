import { useLazyQuery } from "@apollo/client";
import { useOnErrorGraphql } from "@newrai/ant-design";
import { clientNeoDocs } from "@newrai/api-clients";
import useStore from "config/store";
import { Suspense, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { CleanResizeObserver } from "utils/resizeObserver";
import { ACCEPTED_FILE_TYPES_TO_UPLOAD } from "../../queries/neodocs";
import HeaderWrapperMemo from "./components/HeaderWrapper";
import LoadingPage from "./components/LoadingPage";

export default function Base(): JSX.Element {
  const { onErrorGraphql } = useOnErrorGraphql();
  const setAcceptedFileTypes = useStore(state => state.setAcceptedFileTypes);
  const acceptedFileTypes = useStore(state => state.acceptedFileTypes);
  const [listAcceptedFileTypes] = useLazyQuery<AcceptedFileTypesResponseType>(
    ACCEPTED_FILE_TYPES_TO_UPLOAD,
    {
      client: clientNeoDocs,
      onCompleted: data => {
        if (acceptedFileTypes.length === 0) {
          setAcceptedFileTypes(data.__type.acceptedFileTypes.map(item => item.value));
        }
      },
      onError: onErrorGraphql,
    },
  );

  useEffect(() => {
    listAcceptedFileTypes();
    CleanResizeObserver();
  }, []);

  return (
    <div className="layout-wrapper layout-overlay">
      <HeaderWrapperMemo />
      <Suspense fallback={<LoadingPage />}>
        <Outlet />
      </Suspense>
    </div>
  );
}
