import { gql } from "@apollo/client";

export const IS_AUTH_QUERY = gql`
  {
    isAuthenticated {
      status
    }
    getUserByToken {
      accountingFirms
      companies
      isActive
      userCategory {
        id
        name
        slug
      }
      isSuperuser
      id
      firstName
      lastName
      email
      username
      permissions
      language
      isNewraiUser
    }
  }
`;
