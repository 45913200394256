import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, Col, Row } from "antd";

export default function Page404(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Row align="middle">
      <Col span={12} offset="3" className="error-card">
        <Card>
          <div className="text-center">
            <h2>{t("Error 404")}</h2>
            <h2>{t("Oops Page Not Found")}</h2>
            <p>{t("The page you are looking for does not exist or has been moved")}</p>
            <Button type="primary" href="/">
              {t("Go to Homepage")}
            </Button>
          </div>
        </Card>
      </Col>
    </Row>
  );
}
