const currentDomain = window.location.hostname;
const domainArray = currentDomain.split(".").slice(1).join(".");
export const CI_COMMIT_SHORT_SHA = process.env.REACT_APP_CI_COMMIT_SHORT_SHA;

const neoauth =
  process.env.REACT_APP_ENV === "local"
    ? process.env.REACT_APP_NEOAUTH
    : `https://login.${domainArray}/`;
const appUrl =
  process.env.REACT_APP_ENV === "local"
    ? process.env.REACT_APP_URL
    : `${window.location.protocol}//${window.location.hostname}/`;

const dashboard =
  process.env.REACT_APP_ENV === "local"
    ? process.env.REACT_APP_DASHBOARD
    : `https://dashboard.${domainArray}/`;

const neoactivite =
  process.env.REACT_APP_ENV === "local"
    ? process.env.REACT_APP_NEOACTIVITE
    : `https://neoactivite.${domainArray}/`;

const settings = {
  dashboard,
  neoauthApi: `${process.env.REACT_APP_API_NEOAUTH}`,
  centralApi: `${process.env.REACT_APP_API_CENTRALAPI}`,
  neodocsApi: `${process.env.REACT_APP_API_NEODOCS}`,
  authorizeUrl: `${neoauth}o/authorize/`,
  revokeToken: `${neoauth}o/revoke_token/`,
  clientId: `${process.env.REACT_APP_API_CLIENT_ID}`,
  redirectUri: `${appUrl}store-token/`,
  neoactivite: process.env.REACT_APP_API_NEOACTIVITE,
  neoactiviteWS: process.env.REACT_APP_API_NEOACTIVITE_WS,
  fullLoginUrl: "",
};

settings.fullLoginUrl = `${settings.authorizeUrl}?response_type=token&client_id=${
  settings.clientId
}&scope=read&redirect_uri=${appUrl}${window.location.pathname.substring(1)}${encodeURIComponent(
  window.location.search,
)}`;

export default settings;

export const SEARCH_LIMIT = 100;

export const APP_URL = {
  neoActiviteUrl: `${neoactivite}`,
};

export const TreeTableRow = 10;
export const DATE_DISPLAY_FORMAT = "DD/MM/YYYY";

export const dynamsoft = {
  productKey: process.env.REACT_APP_DYNAMSOFT_PRODUCTKEY,
  resourcePath: process.env.REACT_APP_DYNAMSOFT_RESOURCEPATH,
};
